import React, { ReactElement } from 'react'
import { WagmiProvider } from 'wagmi'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import RadomLogo from './icons/Logo2'
import PaymentLinkPreview from './views/PaymentLinkPreview'
import PaymentLink from './views/PaymentLink'
import HostedCheckout from './views/HostedCheckout'
import InvoicePreview from './views/InvoicePreview'
import Invoice from './views/Invoice'
import Subscription from './views/Subscription'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { wagmiConfig } from './util/Managed'
import PresalePreview from './views/PresalePreview'
import Presale from './views/Presale'
import DonationLink from './views/DonationLink'

const queryClient = new QueryClient()

const Container = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

function App(): ReactElement {
  return <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <Container>
              <RadomLogo fill='white' />
            </Container>
          } />
          <Route path='/preview/pay' element={<PaymentLinkPreview />} />
          <Route path='/pay/*' element={<PaymentLink />} />
          <Route path='/preview/invoice' element={<InvoicePreview />} />
          <Route path='/invoice/:invoiceId' element={<Invoice />} />
          <Route path='/checkout/:checkoutId' element={<HostedCheckout />} />
          <Route path='/subscription/:subscriptionId' element={<Subscription />} />
          <Route path='/presale/*' element={<Presale />} />
          <Route path='/preview/presale' element={<PresalePreview />} />
          <Route path='/donate/:donationLinkId' element={<DonationLink />} />
          <Route path='/preview/donation' element={<DonationLink />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </WagmiProvider>
}

export default App
