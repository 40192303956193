import React from 'react'
import { RADOM_COLORS } from '../util/Constants'

function GasPump(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill={RADOM_COLORS.GRAY_DARKEST} viewBox="4 2.7 25.33 26.67" {...props}>
    <path d="M10 8.03353C9.63181 8.03353 9.33333 8.33201 9.33333 8.70019V14.7002H14.6667V8.70019C14.6667 8.33201 14.3682 8.03353 14 8.03353H10Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2.7002C5.79086 2.7002 4 4.49106 4 6.7002V28.0335C4 28.7699 4.59695 29.3669 5.33333 29.3669H18.6667C19.403 29.3669 20 28.7699 20 28.0335V17.3669H20.9253C21.7367 17.3669 22.36 18.0855 22.2452 18.8888L21.7507 22.3508C21.4227 24.6464 23.204 26.7002 25.5228 26.7002C27.6273 26.7002 29.3333 24.9942 29.3333 22.8897V10.5858C29.3333 9.87857 29.0524 9.20029 28.5523 8.70019L24.2761 4.42405L22.3905 6.30967L24 7.91915V11.8535C24 12.2492 24.1758 12.6244 24.4798 12.8778L26.6667 14.7002V22.8897C26.6667 23.5214 26.1546 24.0335 25.5228 24.0335C24.8268 24.0335 24.2921 23.417 24.3905 22.728L24.8851 19.2659C25.2294 16.8561 23.3595 14.7002 20.9253 14.7002H20V6.7002C20 4.49106 18.2091 2.7002 16 2.7002H8ZM17.3333 6.7002C17.3333 5.96382 16.7364 5.36686 16 5.36686H8C7.26362 5.36686 6.66667 5.96382 6.66667 6.7002V26.7002H17.3333V6.7002Z" />
  </svg>
}

export default GasPump
