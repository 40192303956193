import React, { ReactElement } from 'react'
import { LeftSection, RightSection } from '../components/Checkout'
import LoadingBar from '../components/LoadingBar'
import RadomLogo from '../icons/Logo2'
import styled from 'styled-components'
import { FadeIn } from '../components/Animations'

const FadeInWrapper = styled.div`
  animation: ${FadeIn} 0.5s ease;
  font-size: 14px;
  min-width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const LoadingCheckout = (): ReactElement => {
  return <FadeInWrapper>
    <LeftSection
      slantedEdge={false}
      background={'linear-gradient(to top right,#edf4fb,#F8F8FB)'}
      textColor={ 'black'}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: '400px', paddingTop: 40, paddingBottom: 40 }}>
        <LoadingBar style={{ position: 'absolute', top: 30, left: 30, width: 30, height: 30 }} />
        <LoadingBar style={{ width: 160, height: 160 }} />
        <LoadingBar style={{ width: 200, height: 35 }} />
        <LoadingBar style={{ width: 160, height: 25 }} />

      </div>
      <a
        href='https://radom.com'
        target='_blank'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 6,
          cursor: 'pointer',
          userSelect: 'none',
          fontSize: 14,
          color: 'rgba(0, 0, 0, 0.45)',
          fontWeight: 100,
          position: 'absolute',
          bottom: 30,
          left: 30,
          textDecoration: 'none'
        }} rel="noreferrer">
        <span>Checkout by</span>
        <RadomLogo
          width={50}
          fill={'rgba(0, 0, 0, 0.5)'}
        />
      </a>
    </LeftSection>

    <RightSection>
      <div style={{ width: '100%', maxWidth: 420 }}>
        <div style={{
          maxWidth: '400px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto'
        }}>
          <LoadingBar animationSpeed={1} style={{ width: '100%', height: 35 }} />
          <LoadingBar animationSpeed={1.1} style={{ width: '100%', height: 35 }} />
          <LoadingBar animationSpeed={1.2} style={{ width: '100%', height: 35 }} />
          <LoadingBar animationSpeed={1.3} style={{ width: '100%', height: 35 }} />
          <LoadingBar animationSpeed={1.4} style={{ width: '100%', height: 35 }} />
        </div>
      </div>
    </RightSection>
  </FadeInWrapper>
}

export default LoadingCheckout
