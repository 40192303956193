import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Radom, { IManagedPaymentMethodDetails, IManagedPaymentStatus, Profile, Subscription } from '../api/Radom'
import { RADOM_COLORS, formatSubscriptionDuration } from '../util/Constants'
import { Card } from '../components/PayInvoice'
import PayWidget from '../components/PayWidget'
import { FadeInWrapper, LeftSection, RightSection } from '../components/Checkout'
import { TokenCurrencies, Currencies, formatCurrency } from '../util/Currencies'
import RadomLogo from '../icons/Logo2'
import { format, formatDuration, formatRelative, intervalToDuration } from 'date-fns'
import { PrimaryButton, SecondaryButton } from '../components/Button'
import styled from 'styled-components'
import { pickTextColorBasedOnBgColor, shortAddress } from '../util/Util'
import { NetworkToChainMap, getMethod } from '../util/Managed'
import { Chevron } from '../icons/Chevron'
import { CheckmarkWrapper } from '../components/Receipt'
import Checkmark from '../icons/Checkmark'
import LinkExternal from '../icons/LinkExternal'
import { formatExplorerUrl } from '../util/Chains'
import Modal from '../components/Modal'
import { createPublicClient, createWalletClient, custom, erc20Abi, formatUnits, getContract, http, maxUint256 } from 'viem'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useDisconnect, useAccount } from 'wagmi'
import { waitForTransactionReceipt } from 'viem/actions'
import Spinner from '../components/Spinner'
import Plug from '../icons/Plug'

export const ExternalLinkText = styled.a`
  color: ${RADOM_COLORS.BLACK};
  align-items: center;
  display: flex;
  gap: 3px;
  text-decoration: none;
  
  &:hover {
    color: ${RADOM_COLORS.BLACK};
    text-decoration: underline;
  }

  &:active {
    opacity: 0.5;
  }
`

const StatusBadge = styled.div<{ status: 'active' | 'cancelled' }>`
  text-transform: capitalize;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  width: fit-content;

  ${({ status }) => {
    let bgColor = RADOM_COLORS.ERROR_LIGHT as string
    let textColor = RADOM_COLORS.ERROR as string

    switch (status) {
    case 'active':
      bgColor = RADOM_COLORS.SUCCESS_LIGHT as string
      textColor = RADOM_COLORS.SUCCESS as string
      break
    case 'cancelled':
      bgColor = RADOM_COLORS.WARNING_LIGHT
      textColor = RADOM_COLORS.WARNING
      break
    }

    return `
    background-color: ${bgColor};
    color: ${textColor};
  `
  }};
`

const RightSectionInner = styled.div`
  width: 100%;
  max-width: 600px;
  height: fit-content;
  padding: 50px;
    
  @media (max-width: 480px) {
    padding: 5px;
  }
`

const SubscriptionView = (): React.ReactElement => {
  const { subscriptionId } = useParams()

  const [subscription, setSubscription] = useState<Subscription>()
  const [sellerProfile, setSellerProfile] = useState<Profile>()

  const [isPaying, setIsPaying] = useState(false)
  const [customizations, setCustomizations] = useState({
    slantedEdge: false,
    leftPanelColor: RADOM_COLORS.GRAY_MED as string
  })

  const [showPastPayments, setShowPastPayments] = useState(false)

  const [isPreProcessingPayment, setIsPreProcessingPayment] = useState(false)
  const [managedPaymentDetails, setManagedPaymentDetails] = useState<IManagedPaymentMethodDetails>()
  const [isSuccess, setIsSuccess] = useState(false)
  const [successElement, setSuccessElement] = useState<ReactElement>(<></>)

  const section = useRef<HTMLDivElement>(null)

  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState<IManagedPaymentStatus>()

  const [isUpdatingAllowance, setIsUpdatingAllowance] = useState(false)
  const [allowanceAmount, setAllowanceAmount] = useState('')

  const { disconnect } = useDisconnect()
  const { address, isConnected, connector } = useAccount()
  const { open } = useWeb3Modal()

  useEffect(() => {
    fetchSubscription()
  }, [subscriptionId])

  const fetchSubscription = async (): Promise<void> => {
    if (subscriptionId) {
      Radom.getSubscription(subscriptionId)
        .then(s => setSubscription(s))
        .catch(() => {})
    }
  }

  const reconnectWallet = async(): Promise<void> => {
    disconnect()
    await open()
  }

  const editAllowance = async(): Promise<void> => {
    if (!subscription) {
      return
    }

    if (!isConnected) {
      return await reconnectWallet()
    }

    try {
      if (!address) return

      const chain = NetworkToChainMap[subscription.network] as any
      const provider: any = await connector?.getProvider()

      const publicClient = createPublicClient({
        chain,
        transport: http()
      })

      const signer = createWalletClient({
        chain,
        transport: custom(provider)
      })

      if (connector?.switchChain) {
        const chainId = await connector.getChainId()
        if (chainId !== chain.id) {
          await connector?.switchChain({ chainId: chain.id })
        }
      }

      const contract = getContract({
        address: subscription.token as any,
        abi: erc20Abi,
        client: signer
      })

      setIsUpdatingAllowance(true)

      const method = getMethod(subscription.network, subscription.token)
      if (method.name === 'Tether' && BigInt(allowanceAmount) > 0) {
        const hash = await contract.write.approve(
          [subscription.subscriptionType.automatedEVMSubscription?.subscriptionContractAddress as any, 0n],
          {
            account: address as any,
            chain
          }
        )

        if (hash) {
          try {
            await waitForTransactionReceipt(publicClient, {
              hash,
              retryCount: 100,
              retryDelay: 1000
            })
          } catch (err) {
            console.error('Failed to confirm allowance tx', err)
          }
        }
      }

      const hash = await contract.write.approve(
        [subscription.subscriptionType.automatedEVMSubscription?.subscriptionContractAddress as any, maxUint256],
        {
          account: address as any,
          chain
        }
      )

      if (hash) {
        try {
          await waitForTransactionReceipt(publicClient, {
            hash,
            retryCount: 100,
            retryDelay: 1000
          })
        } catch (err) {
          console.error('Failed to confirm allowance tx', err)
        }
      }

      await calculateAllowance()
      setIsUpdatingAllowance(false)
    } catch (err) {
      setIsUpdatingAllowance(false)
    }
  }

  const calculateAllowance = async(): Promise<void> => {
    if (!subscription) {
      return
    }

    if (subscription.subscriptionType.automatedEVMSubscription) {
      const address = subscription.subscriptionType.automatedEVMSubscription.buyerAddress
      const chain = NetworkToChainMap[subscription.network] as any
      const publicClient = createPublicClient({
        chain,
        transport: http()
      })

      const contract = getContract({
        address: subscription.token as any,
        abi: erc20Abi,
        client: publicClient
      })

      try {
        setIsUpdatingAllowance(true)
        const allowance = await contract.read.allowance([
          address as any,
          subscription.subscriptionType.automatedEVMSubscription.subscriptionContractAddress as any
        ])

        const paymentMethod = getMethod(subscription.network, subscription.token)
        let allowanceStr = formatUnits(allowance, paymentMethod.tokenInfo?.decimals ?? 1)
        if (allowanceStr.split('.')[0].length > 9) {
          allowanceStr = '∞'
        }

        setAllowanceAmount(allowanceStr)
        setIsUpdatingAllowance(false)
      } catch (err) {
        setIsUpdatingAllowance(false)
      }
    }
  }

  useEffect(() => {
    if (subscription) {
      if (subscription.customizations) {
        setCustomizations({
          leftPanelColor: RADOM_COLORS.GRAY_MED as string,
          slantedEdge: false,
          ...subscription.customizations
        })
      }

      calculateAllowance()

      Radom.getSellerProfile(subscription.organizationId)
        .then(p => setSellerProfile(p))
        .catch(() => {})
    }
  }, [subscription])

  if (!subscription) {
    return <></>
  }

  return <FadeInWrapper>
    {subscription.product &&
    <LeftSection
      slantedEdge={customizations.slantedEdge}
      background={customizations.leftPanelColor}
      textColor={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'white', 'black')}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: '400px', paddingTop: 40, paddingBottom: 40 }}>

        <div style={{ position: 'absolute', top: 30, left: 30 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            {
              sellerProfile?.logoUrl &&
              <img src={sellerProfile?.logoUrl} style={{ width: 'auto', height: 25, pointerEvents: 'none' }} />
            }
            <span style={{ opacity: 0.75 }}>
              {sellerProfile?.name }
            </span>
          </div>
        </div>

        {
          subscription.product &&
          <>
            <span style={{ fontSize: 28, display: 'flex', flexDirection: 'column' }}>
              {subscription.product.name}
              <span style={{ fontWeight: 100, fontSize: 18, opacity: 0.5 }}>Subscription</span>
            </span>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: 54, fontWeight: 100 }}>
              {
                TokenCurrencies.includes(subscription.product.currency) &&
                <div style={{ width: 32, marginRight: '8px' }}>
                  {Currencies.find(c => c.ticker === subscription.product?.currency)?.icon}
                </div>
              }
              {formatCurrency(subscription.product.currency, subscription.product.price)}

              <span style={{
                fontSize: 16,
                marginLeft: 5,
                fontWeight: 300,
                width: 30,
                opacity: 0.75,
                display: 'inline-block'
              }}>
              per {formatSubscriptionDuration(subscription.product.chargingIntervalSeconds).toLowerCase()}
              </span>

            </div>

            {
              subscription.product.imageUrl &&
              <img style={{
                marginTop: 10,
                maxWidth: 200,
                maxHeight: 200
              }} src={subscription.product.imageUrl} />
            }
          </>
        }

      </div>

      {
        <a
          href='https://radom.com'
          target='_blank'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 6,
            cursor: 'pointer',
            userSelect: 'none',
            fontSize: 14,
            color: pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.45)', 'rgba(0, 0, 0, 0.45)'),
            fontWeight: 100,
            position: 'absolute',
            bottom: 30,
            left: 30,
            textDecoration: 'none'
          }} rel="noreferrer">
          <span>Powered by</span>
          <RadomLogo
            width={60}
            height={14}
            fill={pickTextColorBasedOnBgColor(customizations.leftPanelColor, 'rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.5)')}
          />
        </a>
      }
    </LeftSection>
    }
    <RightSection>

      <div style={{
        overflow: 'scroll',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: '100%'
      }}>

        <RightSectionInner>
          <div
            ref={section}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              height: 'fit-content'
            }}>

            <Card>

              {
                !isPaying &&
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 30 }}>

                <span style={{ fontSize: 18 }}>Subscription details</span>

                {
                  subscription.status === 'active' && subscription.subscriptionType.automatedEVMSubscription && isConnected &&
                  address?.toLowerCase() !== subscription.subscriptionType.automatedEVMSubscription.buyerAddress &&
                  <div style={{ borderRadius: 15, border: `1px solid ${RADOM_COLORS.GRAY_MED}`, overflow: 'hidden' }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 20,
                      backgroundColor: 'rgb(255 223 230)'
                    }}>
                      <span>Incorrect address</span>
                      <SecondaryButton
                        onClick={() => {
                          disconnect()
                        }}
                        style={{
                          padding: 0,
                          border: 0,
                          color: RADOM_COLORS.BLACK,
                          alignItems: 'center',
                          gap: 5,
                          display: 'flex'
                        }}>
                        <Plug
                          style={{ height: 15, width: 'auto', marginBottom: -2 }}
                          strokeWidth={3}
                          stroke={RADOM_COLORS.BLACK} />
                        <span>Disconnect</span>
                      </SecondaryButton>
                    </div>
                    <div style={{
                      padding: 20,
                      color: RADOM_COLORS.GRAY_DARKEST
                    }}>
                      Your connected address is not the subscriber address.
                      Reconnect with the subscriber address to modify your allowance.
                    </div>
                  </div>
                }

                <div style={{
                  gridTemplateColumns: 'auto auto',
                  display: 'grid',
                  rowGap: 30,
                  columnGap: 40
                }}>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <span style={{ opacity: 0.5 }}>Price</span>
                    <span>{formatCurrency(subscription.currency, subscription.amount as any)} {
                      subscription.periodCustomDuration
                        ? `every ${subscription.periodCustomDuration} seconds`
                        : subscription.period
                    }
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <div style={{ display: 'flex', gap: 5 }}>
                      <span style={{ opacity: 0.5 }}>Status</span>
                      {/* <span style={{ color: RADOM_COLORS.BLACK, cursor: 'pointer' }}>Cancel</span> */}
                    </div>
                    <StatusBadge status={subscription.status}>{subscription.status}</StatusBadge>

                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <span style={{ opacity: 0.5 }}>Created</span>
                    <span>{format(new Date(subscription.createdAt), 'MMMM d, yyyy')}</span>
                  </div>

                  {
                    subscription.status === 'active' &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <span style={{ opacity: 0.5 }}>Next payment due</span>
                      <span>{format(new Date(subscription.nextBillingDateAt), 'MMMM d, yyyy')}</span>
                    </div>
                  }

                  {
                    subscription.status === 'active' && subscription.subscriptionType.automatedEVMSubscription &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        <span style={{ opacity: 0.5 }}>Allowance</span>
                        <SecondaryButton
                          onClick={() => {
                            editAllowance()
                          }}
                          style={{
                            padding: 0,
                            color: RADOM_COLORS.BLACK,
                            border: 0
                          }}>
                          { isConnected ? 'Edit' : 'Connect' }
                        </SecondaryButton>
                        {
                          isConnected &&
                          <SecondaryButton
                            onClick={() => {
                              disconnect()
                            }}
                            style={{ padding: 0, border: 0, color: RADOM_COLORS.BLACK }}>
                            <Plug
                              style={{ height: 15, width: 'auto', marginBottom: -2 }}
                              strokeWidth={3}
                              stroke={RADOM_COLORS.BLACK} />
                          </SecondaryButton>
                        }
                      </div>
                      <span>{ isUpdatingAllowance ? <Spinner /> : allowanceAmount}</span>
                    </div>
                  }

                  {
                    subscription.subscriptionType.automatedEVMSubscription &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        <span style={{ opacity: 0.5 }}>Subscriber address</span>
                      </div>
                      <span>
                        <ExternalLinkText
                          onClick={e => e.stopPropagation()}
                          target="_blank" rel="noreferrer"
                          href={formatExplorerUrl(
                            subscription.network,
                            'address',
                            subscription.subscriptionType.automatedEVMSubscription.buyerAddress
                          )}>
                          {shortAddress(subscription.subscriptionType.automatedEVMSubscription.buyerAddress, 6)}
                          <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                        </ExternalLinkText>
                      </span>
                    </div>
                  }

                  {
                    subscription.status !== 'active' && new Date(subscription.nextBillingDateAt).getTime() > new Date().getTime() &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <span style={{ opacity: 0.5 }}>Expires in</span>
                      <span>{formatDuration(
                        intervalToDuration({ start: new Date(subscription.nextBillingDateAt), end: new Date() }),
                        { format: ['years', 'months', 'days'] }
                      )}
                      </span>
                    </div>
                  }
                </div>

                {
                  subscription.status === 'active' && <>
                    <div style={{ width: '100%', height: 1, backgroundColor: RADOM_COLORS.GRAY_LIGHTEST }} />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                      {
                        subscription.subscriptionType.emailInvoiceSubscription &&
                        <PrimaryButton
                          style={{ width: '100%', background: 'black' }}
                          onClick={() => setIsPaying(true)}>
                          Make subscription payment
                        </PrimaryButton>
                      }

                      <SecondaryButton
                        style={{
                          padding: 3,
                          color: RADOM_COLORS.GRAY_DARKER,
                          border: 0,
                          width: 'fit-content'
                        }}
                        onClick={() => setCancelSubscriptionModalOpen(true)}>
                      Cancel subscription
                      </SecondaryButton>
                    </div>
                  </>
                }
              </div>
              }

              {
                isPaying &&
              <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
                <SecondaryButton
                  onClick={() => {
                    setIsPaying(false)
                    setManagedPaymentDetails(undefined)
                  }}
                  style={{
                    border: 0,
                    padding: 0,
                    color: RADOM_COLORS.BLACK,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    opacity: 0.5
                  }}>
                  <Chevron style={{ transform: 'rotate(90deg)' }} />
                  <span>Back</span>
                </SecondaryButton>
                <PayWidget
                  checkoutData={{
                    subscription,
                    chargeCustomerNetworkFee: true,
                    customizations,
                    gateway: {
                      managed: {
                        methods: [
                          {
                            network: subscription.network,
                            token: subscription.token
                          }
                        ]
                      }
                    }
                  }}
                  currency='USD'
                  isPreProcessingPayment={isPreProcessingPayment}
                  isSuccess={isSuccess}
                  successElement={successElement}
                  paymentStatus={paymentStatus}
                  onCheckout={async (c) => {
                    setIsPreProcessingPayment(true)
                    try {
                      const res = await Radom.createSubscriptionPayment(subscription.id)

                      if (res.paymentMethodDetails.managed) {
                        setManagedPaymentDetails(res.paymentMethodDetails.managed)
                      }

                      const checkPaymentInterval = setInterval(() => {
                        if (!res.paymentMethodDetails.managed) {
                          return
                        }

                        Radom.getManagedPaymentStatus(res.paymentMethodDetails.managed?.paymentId).then(res => {
                          setPaymentStatus(res)

                          if (res.status === 'paymentConfirmed') {
                            setIsSuccess(true)
                            clearInterval(checkPaymentInterval)

                            setSuccessElement(
                              <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10
                              }}>
                                <CheckmarkWrapper>
                                  <Checkmark width={30} height={30} animationDelay='0.5s' animationSpeed='0.75s' />
                                </CheckmarkWrapper>
                                <span style={{ fontSize: 18, color: RADOM_COLORS.BLACK }}>Payment successful</span>
                              </div>
                            )
                          }
                        }).catch(() => {})
                      }, 1000)
                    } catch (err) {

                    }

                    setIsPreProcessingPayment(false)
                  }}
                  managedPaymentDetails={managedPaymentDetails}
                  onQuoteUpdate={ () => {} }
                  disableAnimation />
              </div>
              }
            </Card>

            {
              subscription.subscriptionType.emailInvoiceSubscription &&
              <SecondaryButton
                style={{
                  padding: 3,
                  color: RADOM_COLORS.GRAY_DARKER,
                  border: 0
                }}
                onClick={() => setShowPastPayments(!showPastPayments) }>
                <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
                  <span>{showPastPayments ? 'Hide' : 'Show'} past payments</span>
                  <Chevron style={{ opacity: 0.4, rotate: showPastPayments ? '180deg' : '0deg', transition: '0.2s ease all' }}/>
                </div>
              </SecondaryButton>
            }

            {
              showPastPayments &&
            subscription.payments.map((p, i) => {
              const method = getMethod(p.network, p.token)

              return <Card key={i}>
                <span style={{ fontSize: 16 }}>Payment #{i + 1}</span>

                <div style={{ width: '100%', height: 1, backgroundColor: RADOM_COLORS.GRAY_LIGHTEST, margin: '20px 0' }} />

                <div style={{
                  gridTemplateColumns: 'repeat(2, fit-content(100%))',
                  display: 'grid',
                  rowGap: 30,
                  columnGap: 40
                }}>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <span style={{ opacity: 0.5 }}>Network</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <div style={{
                        width: 20,
                        height: 20,
                        backgroundImage: `url(${method.tokenInfo?.logo ?? method.logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                      }} />
                      <span>

                        {method.tokenInfo?.networkName ?? method.name}
                      </span>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <span style={{ opacity: 0.5 }}>Amount</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <div style={{
                        width: 20,
                        height: 20,
                        backgroundImage: `url(${method.logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                      }}>
                        {
                          method.tokenInfo && <div style={{
                            background: 'rgba(255, 255, 255, 0.95)',
                            borderRadius: '100%',
                            width: 12,
                            height: 12,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: -1,
                            right: -5,
                            boxShadow: '0 0 1px'
                          }}>
                            <div style={{
                              width: 8,
                              height: 8,
                              backgroundImage: `url(${method.tokenInfo.logo})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat'
                            }} />
                          </div>
                        }
                      </div>
                      <span>{Number(p.amount)}</span>
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <span style={{ opacity: 0.5 }}>Transactions</span>
                    {
                      p.transactionHashes.map(t => <ExternalLinkText
                        key={t}
                        onClick={e => e.stopPropagation()}
                        target="_blank" rel="noreferrer"
                        href={formatExplorerUrl(
                          p.network,
                          'tx',
                          t
                        )}>
                        {shortAddress(t, 6)}
                        <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                      </ExternalLinkText>
                      )
                    }
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <span style={{ opacity: 0.5 }}>Date</span>
                    <span>{formatRelative(new Date(p.date), new Date())}</span>
                  </div>

                </div>
              </Card>
            })
            }

          </div>
        </RightSectionInner>
      </div>

    </RightSection>

    <Modal
      title='Cancel subscription'
      visible={cancelSubscriptionModalOpen}
      onClose={() => setCancelSubscriptionModalOpen(false)}>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '10px 30px',
        gap: 20,
        fontSize: 14
      }}>
        <span>
          Are you sure you would like to cancel your subscription?
        </span>
      </div>

      <div style={{ padding: 20 }}>

        <PrimaryButton
          style={{ width: '100%', backgroundColor: RADOM_COLORS.BLACK }}
          isLoading={isCancelling}
          onClick={async () => {
            if (!subscriptionId) return
            setIsCancelling(true)
            Radom.cancelSubscription(subscriptionId)
              .then(async () => await fetchSubscription())
              .catch(() => {})
              .finally(() => {
                setIsCancelling(false)
                setCancelSubscriptionModalOpen(false)
              })
          }}>
          Cancel subscription
        </PrimaryButton>
      </div>

    </Modal>

  </FadeInWrapper>
}

export default SubscriptionView
