import React, { PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import Copy from '../icons/Copy'
import { SecondaryButton } from '../components/Button'
import { RADOM_COLORS } from '../util/Constants'

const StyledCopyButton = styled(SecondaryButton)`
  padding: 2px 4px;
  font-size: 10px;
  text-transform: uppercase;
  background: ${RADOM_COLORS.GRAY_MED};
  color: ${RADOM_COLORS.GRAY_DARKEST};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
  border: none;
`

interface CopyButtonProps extends PropsWithChildren {
  onClick: () => void
  style?: React.CSSProperties
  iconColor?: string
}

const CopyButton: React.FC<CopyButtonProps> =
 ({ onClick, style, iconColor = RADOM_COLORS.GRAY_DARKEST }): React.ReactElement => {
   const [copied, setCopied] = useState(false)

   useEffect(() => {
     if (copied) {
       const timeout = setTimeout(() => setCopied(false), 3000)
       return () => clearTimeout(timeout)
     }
   }, [copied])

   return (
     <StyledCopyButton
       onClick={() => { setCopied(true); onClick() }}
       style={style}>
       <span>{copied ? 'Copied' : 'Copy'}</span>
       <Copy style={{ height: 10 }} stroke={iconColor} />
     </StyledCopyButton>
   )
 }

export default CopyButton
