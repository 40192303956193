/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Radom, { IInvoice, IManagedPaymentMethodDetails, IManagedPaymentStatus, LineItem, PriceQuote, ProductWithQuantity } from '../api/Radom'
import { CheckoutData } from './Checkout'
import PayWidget, { CheckoutParams } from './PayWidget'
import Spinner from './Spinner'
import Checkmark from '../icons/Checkmark'
import { RADOM_COLORS, WINDOW_SIZE } from '../util/Constants'
import { formatCurrency } from '../util/Currencies'
import { convertItem, getTotalSum } from '../util/Products'
import { CheckmarkWrapper } from '../components/Receipt'
import { shortAddress } from '../util/Util'
import { Chevron } from '../icons/Chevron'

export const StatusBadge = styled.div<{ status: 'pending' | 'paid' | 'voided' | 'overdue' }>`
  text-transform: capitalize;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 5px;
  float: right;
  color: white;
  font-size: 14px;

  ${({ status }) => {
    let bgColor = RADOM_COLORS.ERROR_LIGHT as string
    let textColor = RADOM_COLORS.ERROR as string

    switch (status) {
    case 'paid':
      bgColor = RADOM_COLORS.SUCCESS_LIGHT as string
      textColor = RADOM_COLORS.SUCCESS as string
      break
    case 'pending':
      bgColor = RADOM_COLORS.GRAY_DARK
      textColor = RADOM_COLORS.GRAY_DARKEST as string
      break
    }
    return `
      background-color: ${bgColor};
      color: ${textColor};
    `
  }};
`

const LineItemGrid = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 8fr 1fr;
  row-gap: 18px;
  padding: 20px 0px;
  font-size: 14px;

  > *:nth-child(2n) {
    text-align: right;
  }
`

const InvoiceDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  margin: 24px 0px;
  row-gap: 8px;
  column-gap: 20px;

  > *:nth-child(2n+1) {
    color: ${RADOM_COLORS.GRAY_DARKER};
  }
`

export const Card = styled.div`
  font-size: 14px;
  padding: 50px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${RADOM_COLORS.GRAY_MED};
  box-shadow: 0 1px 2px 0px #0000000a;

  @media (max-width: ${WINDOW_SIZE.MOBILE_LARGE}) {
    padding: 30px;
  }
`

export const Container = styled.div`
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  animation: fadeSlide 0.5s ease;

  @keyframes fadeSlide {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }

    to {
      opacity: 100%;
      transform: translateY(0%);
    }
  }

  @media (max-width: ${WINDOW_SIZE.MOBILE_LARGE}) {
    padding: 30px;
  }
`

const ViewInvoiceButton = styled.div`
  cursor: pointer;
  margin-top: 20px;
  color: ${RADOM_COLORS.GRAY_DARKER};
  user-select: none;
  display: flex;
  align-items: center;
  gap: 5px;

  :hover {
    opacity: 0.5;
  }
`

interface IProps {
  invoiceId?: string
  previewData?: IInvoice
}

const PayInvoice = (props: IProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [invoiceData, setInvoice] = useState<IInvoice>()
  const [priceQuotes, setPriceQuotes] = useState<PriceQuote[]>([])
  const [totalSum, setTotalSum] = useState(0)
  const [currency, setCurrency] = useState('USD')

  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const [isPreProcessingPayment, setIsPreProcessingPayment] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [managedPaymentDetails, setManagedPaymentDetails] = useState<IManagedPaymentMethodDetails>()
  const [successElement, setSuccessElement] = useState<React.ReactElement>(<></>)
  const [paymentStatus, setPaymentStatus] = useState<IManagedPaymentStatus>()

  const getQuotes = async (invoice: { products: ProductWithQuantity[], lineItems: LineItem[] }): Promise<void> => {
    const productCurrencies: string[] = [
      ...new Set([...invoice.products.map(p => p.product.currency) ?? []]),
      ...invoice.lineItems.map(l => l.currency ?? 'USD') ?? []
    ]
    if (productCurrencies.length === 0) return
    if (productCurrencies.every(c => priceQuotes.findIndex(q => q.from === c && q.to === currency) !== -1)) {
      return
    }
    setIsLoading(true)
    const quotes = await Radom.getPriceQuotes(productCurrencies, [currency])
    setIsLoading(false)
    if (quotes) {
      setPriceQuotes(quotes)
    }
  }

  const checkoutData: CheckoutData = useMemo(() => {
    const c: CheckoutData = {
      isInvoice: true,
      sellerAddress: '',
      sellerLogoUrl: '',
      sellerName: '',
      products: [],
      lineItems: [],
      gateway: { },
      customizations: {
        allowDiscountCodes: false,
        slantedEdge: false
      },
      chargeCustomerNetworkFee: false
    }
    if (invoiceData) {
      c.sellerAddress = shortAddress(invoiceData.sellerAddress, 6)
      if (invoiceData.seller) {
        c.sellerLogoUrl = invoiceData.seller.logoUrl ?? ''
        c.sellerName = invoiceData.seller.name || invoiceData.seller.email
      }
      c.products = invoiceData.products
      c.lineItems = invoiceData.lineItems?.map(l => {
        return {
          ...l,
          currency: l.currency ?? 'USD',
          chargingIntervalSeconds: 0
        }
      })
      c.gateway = invoiceData.gateway
    }
    return c
  }, [invoiceData, priceQuotes])

  const onCheckout = async ({ selfCustodial, managed, priceQuotes }: CheckoutParams): Promise<void> => {
    if (props.previewData) return
    setErrorMessage('')

    if (!invoiceData) {
      setErrorMessage('Payment link data not set. Please refresh and try again.')
      return
    }

    if (managed) {
      setIsPreProcessingPayment(true)

      const { selectedMethod: method } = managed

      try {
        if (!invoiceData) throw new Error('Invoice not found')
        const res = await Radom.createInvoicePayment({
          invoiceId: invoiceData.id,
          paymentMethod: {
            managed: {
              method: {
                network: method.tokenInfo?.networkName ?? method.name,
                token: method.tokenInfo?.tokenAddress ?? undefined
              }
            }
          }
        })

        if (!res.paymentMethodDetails.managed) {
          throw new Error('Unexpected empty managed payment method details')
        }

        setManagedPaymentDetails(res.paymentMethodDetails.managed)

        const checkPaymentInterval = setInterval(() => {
          if (!res.paymentMethodDetails.managed) {
            return
          }

          Radom.getManagedPaymentStatus(res.paymentMethodDetails.managed?.paymentId).then(res => {
            setPaymentStatus(res)

            if (res.status === 'paymentConfirmed') {
              setIsSuccess(true)
              clearInterval(checkPaymentInterval)

              setSuccessElement(
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10
                }}>
                  <CheckmarkWrapper>
                    <Checkmark width={30} height={30} animationDelay='0.5s' animationSpeed='0.75s' />
                  </CheckmarkWrapper>
                  <span style={{ fontSize: 18, color: RADOM_COLORS.BLACK }}>Payment successful</span>
                </div>
              )
            }
          }).catch(() => {})
        }, 1000)
      } catch (err) {
        console.error(err)
        setErrorMessage(err.reason || err.message)
      }

      setIsPreProcessingPayment(false)
    }
  }

  const onQuoteUpdate = async (): Promise<void> => {
    if (invoiceData) {
      getQuotes(invoiceData)
    }
  }

  useEffect(() => {
    document.title = 'Radom - Invoice'
    if (props.invoiceId) {
      Radom.getInvoice(props.invoiceId)
        .then(invoice => {
          setInvoice(invoice)
          getQuotes(invoice)
            .then(() => {
              setIsLoading(false)
            })
            .catch((err) => console.error(err))
        })
    } else if (props.previewData) {
      getQuotes(props.previewData)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (checkoutData) {
      let primaryCurrency: string = 'USD'
      if (checkoutData.products && checkoutData.products?.length > 0) {
        primaryCurrency = checkoutData.products[0].product.currency
      } else if (checkoutData.lineItems && checkoutData.lineItems.length > 0 && checkoutData.lineItems[0].currency) {
        primaryCurrency = checkoutData.lineItems[0].currency
      }
      setCurrency(primaryCurrency)
      const [sum] = getTotalSum(checkoutData, priceQuotes, primaryCurrency)
      setTotalSum(sum)
    }
  }, [checkoutData])

  useEffect(() => {
    if (props.previewData) {
      let primaryCurrency: string = 'USD'
      if (props.previewData.products && props.previewData.products.length > 0) {
        primaryCurrency = props.previewData.products[0].product.currency
      } else if (props.previewData.lineItems &&
          props.previewData.lineItems.length > 0 &&
          props.previewData.lineItems[0].currency
      ) {
        primaryCurrency = props.previewData.lineItems[0].currency
      }
      setCurrency(primaryCurrency)
      setInvoice(props.previewData)
    }
  }, [props.previewData])

  if (!invoiceData && !props.previewData) {
    return <div style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Spinner />
    </div>
  }

  return <div style={{ minWidth: '100vw', minHeight: '100vh' }}>
    <Container>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center', fontSize: '14px', color: RADOM_COLORS.GRAY_DARKER }}>
        {
          checkoutData.sellerLogoUrl &&
            <img src={checkoutData.sellerLogoUrl} style={{ maxWidth: 80, maxHeight: 25 }} />
        }
        {
          invoiceData?.seller?.name && invoiceData.seller.name.length > 0
            ? invoiceData.seller.name
            : shortAddress(invoiceData?.sellerAddress ?? '', 4)
        }
      </div>
      <Card>
        <div style={{ fontSize: '36px' }}>
          {
            !isLoading && <div>{formatCurrency(currency, totalSum)}</div>
          }
          {
            isLoading && <Spinner />
          }
          { invoiceData?.overdueAt &&
            <div style={{
              display: 'flex',
              gap: 8,
              alignItems: 'center',
              fontSize: '14px',
              color: RADOM_COLORS.GRAY_DARKER,
              margin: '10px 0px'
            }}>
              <span>Due by {format(new Date(invoiceData.overdueAt), 'MMMM do, yyyy')}</span>
              {
                invoiceData?.status && invoiceData?.status !== 'pending' &&
                <StatusBadge status={invoiceData?.status}>{invoiceData?.status}</StatusBadge>
              }
            </div>}
        </div>
        <InvoiceDetailsGrid>
          <div>Invoice</div>
          <div>{props.invoiceId ?? 'example-invoice-uuid'}</div>
          <div>To</div>
          <div>{invoiceData?.customer.name || invoiceData?.customer.email}</div>
          <div>From</div>
          <div>{
            invoiceData?.seller?.name && invoiceData.seller.name.length > 0
              ? invoiceData.seller.name
              : shortAddress(invoiceData?.sellerAddress ?? '', 4)
          }</div>
          {invoiceData?.memo && <>
            <div>Memo</div>
            <div>{invoiceData.memo}</div>
          </>}
        </InvoiceDetailsGrid>
        <ViewInvoiceButton onClick={() => setDetailsExpanded(!detailsExpanded)}>
          <span>
            {detailsExpanded ? 'Hide' : 'Show'} invoice details
          </span>
          <Chevron style={{ opacity: 0.4, rotate: detailsExpanded ? '180deg' : '0deg', transition: '0.2s ease all' }}/>
        </ViewInvoiceButton>
        <div style={{ transition: '0.5s ease', overflow: 'scroll', maxHeight: `${detailsExpanded ? '200px' : '0'}` }}>
          <LineItemGrid>
            {
              // Map product to line item
              invoiceData?.products.map(line => [
                <div key="name">
                  <div>{line.product.name}</div>
                  <div style={{ color: RADOM_COLORS.GRAY_DARKER }}>Qty {line.quantity ?? 1}</div>
                </div>,
                <div key="price">
                  {
                    isLoading && <Spinner />
                  }
                  {
                    !isLoading && <div>
                      {formatCurrency(line.product.currency, line.product.price * (line.quantity ?? 1))}
                      {
                        line.product.currency !== currency &&
                          <div style={{ color: RADOM_COLORS.GRAY_DARKER }}>{
                            formatCurrency(currency,
                              convertItem({ ...line.product, quantity: line.quantity }, currency, priceQuotes)
                            )
                          }</div>
                      }
                    </div>
                  }
                </div>
              ])
            }
            {
              // Map product to line item
              invoiceData?.lineItems?.map(line => [
                <div key="name">
                  <div>{line.name}</div>
                  <div style={{ color: RADOM_COLORS.GRAY_DARKER }}>Qty {line.quantity ?? 1}</div>
                </div>,
                <div key="price">
                  {formatCurrency(line.currency ?? 'USD', line.price * (line.quantity ?? 1))}
                  {
                    line.currency !== currency &&
                      <div style={{ color: RADOM_COLORS.GRAY_DARKER }}>{
                        formatCurrency(currency,
                          convertItem(line, currency, priceQuotes)
                        )
                      }</div>
                  }
                </div>
              ])
            }
          </LineItemGrid>
        </div>
      </Card>
      <Card>
        {
          (invoiceData?.status === 'pending' || invoiceData?.status === 'overdue' || props.previewData) &&
          <PayWidget
            currency={currency}
            checkoutData={checkoutData}
            onCheckout={onCheckout}
            onQuoteUpdate={async () => await onQuoteUpdate()}
            isSuccess={isSuccess}
            isPreProcessingPayment={isPreProcessingPayment}
            errorMessage={errorMessage}
            managedPaymentDetails={managedPaymentDetails}
            successElement={successElement}
            paymentStatus={paymentStatus}
          />
        }
        {
          invoiceData?.status === 'voided' &&
          <div style={{ fontSize: 16, color: RADOM_COLORS.ERROR, textAlign: 'center' }}>
            Invoice has been voided
          </div>
        }
        {
          invoiceData?.status === 'paid' &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            userSelect: 'none'
          }}>
            <CheckmarkWrapper style={{ width: 20, height: 20 }}>
              <Checkmark width={20} height={20} animationDelay='0.5s' animationSpeed='0.75s' />
            </CheckmarkWrapper>
            <div style={{ fontSize: 16, color: RADOM_COLORS.NICE_GREEN, textAlign: 'center' }}>
            Invoice has been paid
            </div>
          </div>
        }
      </Card>
    </Container>
  </div>
}

export default PayInvoice
