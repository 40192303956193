import React from 'react'
import { useParams } from 'react-router-dom'
import PayInvoice from '../components/PayInvoice'

const Invoice = (): React.ReactElement => {
  const { invoiceId } = useParams()
  return <>
    {
      invoiceId &&
      <PayInvoice
        invoiceId={invoiceId}
      />
    }
  </>
}

export default Invoice
