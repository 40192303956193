import React from 'react'
import { useLocation } from 'react-router-dom'
import { IInvoice } from '../api/Radom'
import PayInvoice from '../components/PayInvoice'

const InvoicePreview = (): React.ReactElement => {
  const location = useLocation()
  const invoiceData: IInvoice = JSON.parse(Buffer.from(location.hash.replace('#', ''), 'base64').toString('utf8'))

  return <PayInvoice
    previewData={invoiceData}
  />
}

export default InvoicePreview
