import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import Checkout from '../components/Checkout'
import LoadingCheckout from './LoadingCheckout'

function PaymentLinkPreview(): ReactElement {
  const location = useLocation()
  const paymentLinkData = (JSON.parse(Buffer.from(location.hash.replace('#', ''), 'base64').toString('utf8')))

  if (!paymentLinkData || paymentLinkData.products.length <= 0) {
    return <LoadingCheckout />
  }

  return <Checkout
    checkoutData={{
      ...paymentLinkData,
      products: paymentLinkData.products.map(p => {
        return {
          product: p,
          quantity: 1
        }
      })
    }}
    onCheckout={() => {}}
    isPreProcessingPayment={false}
    isSuccess={false}
    successElement={<></>}
  />
}

export default PaymentLinkPreview
