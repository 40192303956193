import React, { ReactElement, CSSProperties } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { Chevron } from '../icons/Chevron'
import { InputLabel } from './Input'
import { ScaleIn } from './Animations'
import { Link } from 'react-router-dom'

const DropdownWrapper = styled.div<{ disabled?: boolean }>`
  user-select: none;
  cursor: ${({ disabled }) => disabled ? 'inherit' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  position: relative;
`

const DropdownInner = styled.div<{ open: boolean, transparent?: boolean, disabled?: boolean }>`
  border: 1px solid;
  padding: 6px 10px;
  border-radius: 5px;
  transition: 0.2s ease all;
  background-color: ${({ transparent }) =>
    transparent
      ? 'transparent'
      : 'white'};
  border-color: ${({ transparent, open }) => (open ? RADOM_COLORS.GRAY_DARK : transparent ? 'transparent' : RADOM_COLORS.GRAY_DARK)};
  ${({ open, transparent, disabled }) =>
    open
      ? `box-shadow: 0 0 5px ${RADOM_COLORS.GRAY_DARK};`
      : !transparent && !disabled && 'box-shadow: 0 1px 1px rgb(226 228 239 / 50%);'}
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
  height: 100%;

  :hover {
    ${({ disabled }) => !disabled && `border-color: ${RADOM_COLORS.GRAY_DARK};`}
    ${({ open, transparent, disabled }) => !open && !transparent && !disabled && `box-shadow: 0 1px 1px ${RADOM_COLORS.GRAY_MED};`}
  }

  :active {
    opacity: 0.75;
  }
`

const DropdownContent = styled.div<{ visible: boolean }>`
  cursor: auto;
  display: ${props => props.visible ? 'block' : 'none'};
  position: fixed;
  z-index: 99;
`

const DropdownContentInner = styled.div<{ overflow?: string }>`
  max-height: 40vh;
  animation: ${ScaleIn} 0.1s;
  transform-origin: top center;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  ${({ overflow }) => overflow ? `overflow: ${overflow}` : 'overflow: scroll'}
`

export const DropdownItem = styled.div`
  cursor: pointer;
  padding: 10px;
  transition: 0.2s ease all;
  opacity: 1;
  
  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  }
  
  :active {
    opacity: 0.5;
  }
`

export const DropdownLink = styled(Link)`
  cursor: pointer;
  padding: 10px;
  transition: 0.2s ease all;
  opacity: 1;
  font-size: 14px;
  width: 100%;
  display: block;
  text-decoration: none;
  color: black;
  
  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  }
  
  :active {
    opacity: 0.5;
  }
`

export const DropdownContentHeader = styled.div`
  display: flex;
  width: 100%;
  line-height: 12px;
  align-items: center;
  justify-content: space-between;
`

export const DropdownContentBody = styled.div`
  max-height: 300px;
  overflow: scroll;
`

export const DropdownTextInput = styled.input<{ value: string }>`
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 12px;
`

type AlignmentPreference = 'left' | 'right'

interface IProps {
  preferredAlignment?: AlignmentPreference
  selectedContent: React.ReactNode
  dropdownContent: React.ReactNode
  onClose?: () => any
  onCloseFn?: (fn: any) => any
  transparent?: boolean
  whiteChevron?: boolean
  noChevron?: boolean
  disabled?: boolean
  overflow?: string
  onClick?: () => void
  outerStyle?: CSSProperties
  innerStyle?: CSSProperties
}

interface IState {
  open: boolean
  top: number
  left: number
}

export default class Dropdown extends React.Component<IProps, IState> {
  dropdownWrapper: HTMLDivElement | null
  dropdownContent: HTMLDivElement
  state = {
    open: false,
    top: 0,
    left: 0
  }

  componentDidUpdate = (prevProps): void => {
    if (prevProps.dropdownContent !== this.props.dropdownContent) {
      this.repositionDropdown()
    }
  }

  componentDidMount = (): void => {
    window.addEventListener('click', e => {
      if (!this.dropdownWrapper || !this.state.open) {
        return
      }

      const isClickInDropdown = this.dropdownWrapper === e.target || this.dropdownWrapper.contains(e.target as any)
      if (!isClickInDropdown) {
        this.toggleDropdown(false)
      }
    })

    if (this.props.onCloseFn) {
      this.props.onCloseFn(() => this.toggleDropdown(false))
    }
  }

  toggleDropdown = (open = !this.state.open): void => {
    if (this.props.disabled) {
      return
    }

    if (open) {
      document.addEventListener('wheel', this.repositionDropdown)
      window.addEventListener('resize', this.repositionDropdown)
    } else {
      document.removeEventListener('wheel', this.repositionDropdown)
      window.removeEventListener('resize', this.repositionDropdown)
    }

    if (this.state.open && !open && this.props.onClose) {
      this.props.onClose()
    }

    this.setState({ open }, () => {
      this.props.onClick?.()
    })
  }

  repositionDropdown = (): void => {
    if (!this.dropdownWrapper || !this.dropdownContent || !this.state.open) {
      return
    }

    const wrapper = this.dropdownWrapper.getBoundingClientRect()
    const rect = this.dropdownContent.getBoundingClientRect()

    const bottomAlign = wrapper.height + wrapper.top + 5
    const topAlign = wrapper.y - rect.height - 5

    let top = bottomAlign
    if (bottomAlign + rect.height > window.innerHeight && topAlign >= 0) {
      // Position dropdown above dropdownWrapper if it's at the bottom of the screen and there's space above
      top = topAlign
    } else if (bottomAlign + rect.height > window.innerHeight && topAlign < 0) {
      // If there's not enough space above or below the dropdownWrapper, just center the dropdown vertically
      top = window.innerHeight / 2 - rect.height / 2
    }

    let left = wrapper.x
    if (left + rect.width > window.innerWidth) {
      // Position dropdown to the left of the dropdownWrapper if it's going off the screen to the right
      left = wrapper.x - rect.width + wrapper.width
    }

    this.setState({ top, left })
  }

  render(): React.ReactNode {
    return (
      <DropdownWrapper
        ref={dropdownWrapper => { if (dropdownWrapper) this.dropdownWrapper = dropdownWrapper }}
        disabled={this.props.disabled}>
        <DropdownInner
          transparent={this.props.transparent}
          open={this.state.open}
          onClick={() => this.toggleDropdown()}
          disabled={this.props.disabled}
          style={this.props.outerStyle}>
          {this.props.selectedContent}
          {
            !this.props.noChevron &&
            <Chevron fill={this.props.whiteChevron ? 'white' : 'black'} style={{
              opacity: 0.3,
              transition: '0.2s ease all',
              transform: this.state.open ? 'rotate(180deg)' : 'rotate(0deg)',
              minWidth: 15
            }} />
          }
        </DropdownInner>
        {
          this.state.open &&
          <DropdownContent
            style={{
              minWidth: this.dropdownWrapper?.clientWidth,
              top: this.state.top,
              left: this.state.left
            }}
            ref={r => {
              if (!r || r === this.dropdownContent) return
              new ResizeObserver(this.repositionDropdown).observe(r)
              this.dropdownContent = r
              this.repositionDropdown()
            }}
            visible={this.state.open}>
            <DropdownContentInner overflow={this.props.overflow} style={this.props.innerStyle}>
              {this.props.dropdownContent}
            </DropdownContentInner>
          </DropdownContent>
        }
      </DropdownWrapper>
    )
  }
}

type DropdownWithLabelProps = IProps & {
  label: string
}

export const DropdownWithLabel =
  ({ label, selectedContent, dropdownContent, onCloseFn }: DropdownWithLabelProps): ReactElement => {
    return <>
      <InputLabel>{ label }</InputLabel>
      <Dropdown selectedContent={selectedContent} dropdownContent={dropdownContent} onCloseFn={onCloseFn} />
    </>
  }
