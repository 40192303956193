import React from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { BaseInputStyles } from './Input'

const StyledInput = styled.input<{ error?: boolean, fontSize?: string, align?: string }>`
  ${BaseInputStyles}
  position: relative;
  outline: none;
  border: 1px solid;
  border-color: ${RADOM_COLORS.GRAY_DARK};
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.bg1};
  font-size: ${({ fontSize }) => fontSize ?? '28px'};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  text-align: left;
  padding: 8px 6px;
  border-radius: 5px;
  transition: 0.2s ease all;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARK};
  }
  
  :focus {
    border-color: ${RADOM_COLORS.GRAY_DARK};
    box-shadow: 0 0 5px ${RADOM_COLORS.GRAY_DARK};
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

export default React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  prependSymbol,
  ...rest
}: {
  value?: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  prependSymbol?: string | undefined
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.target.value
    if (/^\d*\.?\d*$/.test(newValue)) {
      onUserInput(newValue)
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    const charCode = event.code
    if (charCode === 'Minus') {
      event.preventDefault()
    } else if (charCode !== 'delete' && charCode > 'a' && (charCode < '0' || charCode > '9')) {
      event.preventDefault()
    }
  }

  return (
    <StyledInput
      {...rest}
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      inputMode="numeric"
      autoComplete="off"
      autoCorrect="off"
      placeholder={placeholder ?? '0.00'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  )
})
