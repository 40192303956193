import * as Sentry from '@sentry/react'
import { captureConsoleIntegration } from '@sentry/integrations'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { IS_PROD } from './util/Constants'

if (IS_PROD) {
  Sentry.init({
    dsn: 'https://55021c91df5eef7cbbf78d01e56c83df@o4506811525824512.ingest.sentry.io/4506811527200768',
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

console.info(`
██████╗  █████╗ ██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔══██╗██╔══██╗██╔═══██╗████╗ ████║
██████╔╝███████║██║  ██║██║   ██║██╔████╔██║
██╔══██╗██╔══██║██║  ██║██║   ██║██║╚██╔╝██║
██║  ██║██║  ██║██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚═╝     ╚═╝
Welcome to Radom!
Build: ${process.env.RADOM_BUILD_VERSION}
`)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('app')!)
root.render(<App />)
