
import React from 'react'

function Arrow(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg" {...props}
  >
    <path
      d="M19 12L5 12"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19L19 12L12 5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}

export default Arrow
