import React, { ReactElement } from 'react'
import Presale from '../components/Presale'
import { useLocation } from 'react-router-dom'
import LoadingPresale from '../components/LoadingPresale'

function PresalePreview(): ReactElement {
  const location = useLocation()
  const paymentLinkData = (JSON.parse(Buffer.from(location.hash.replace('#', ''), 'base64').toString('utf8')))

  if (!paymentLinkData || paymentLinkData.products.length <= 0) {
    return <LoadingPresale />
  }

  return <Presale
    checkoutData={{
      ...paymentLinkData,
      products: paymentLinkData.products.map(p => {
        return {
          product: p,
          quantity: 1
        }
      })
    }}
    onCheckout={() => {}}
    isPreProcessingPayment={false}
    isSuccess={false}
    successElement={<></>}
  />
}

export default PresalePreview
