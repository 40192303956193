import { IDiscountCode, LineItem, PriceQuote } from '../api/Radom'
import { CheckoutData } from '../components/Checkout'

export function convertItem(
  item: Omit<LineItem, 'invoiceId'>,
  currency: string,
  priceQuotes: PriceQuote[]
): number {
  const conversion = priceQuotes.find(p => p.from === item.currency && p.to === currency)?.value ?? 1
  return item.price * (item.quantity ?? 1) * conversion
}

export function estimateNetworkFee(dollarNumber: number): number {
  return Math.max(0.5, Math.min(2.5, dollarNumber * 0.008))
}

export function convertValue(
  value: number,
  from: string,
  to: string,
  priceQuotes: PriceQuote[],
  chargeCustomerNetworkFee: boolean,
  paymentMethodDiscount: number = 0
): number {
  const conversion = priceQuotes.find(p => p.from === from.toUpperCase() && p.to === to.toUpperCase())?.value ?? 1
  const networkFeeDollarValue = estimateNetworkFee(value * (priceQuotes.find(p => p.from === from.toUpperCase() && p.to === 'USD')?.value ?? 1))
  const networkFeeConvertedValue = networkFeeDollarValue / (priceQuotes.find(p => p.from === from.toUpperCase() && p.to === 'USD')?.value ?? 1)

  return Number(
    (
      (value * conversion * (1 - paymentMethodDiscount)) +
      (chargeCustomerNetworkFee
        ? networkFeeConvertedValue * conversion
        : 0)
    )
      .toFixed(12))
}

export function getTotalSum(
  checkoutData: CheckoutData,
  priceQuotes: PriceQuote[],
  primaryCurrency: string,
  discount?: IDiscountCode
): [number, number] {
  let total: number = 0
  let subscriptionTotal: number = 0

  if (checkoutData.total) {
    total = checkoutData.total
    return [total, subscriptionTotal]
  }

  if (checkoutData.products) {
    total += checkoutData.products.reduce((total: number, p) => {
      let subTotal = convertItem({ ...p.product, quantity: p.quantity }, primaryCurrency, priceQuotes)
      if (discount?.products.includes(p.product.id)) {
        switch (discount.model.modelType) {
        case 'fixed':
          subTotal -= discount.model.amountOff
          break
        case 'percentage':
          subTotal -= subTotal * discount.model.amountOff / 100
        }
      }
      if (p.product.chargingIntervalSeconds > 0) subscriptionTotal += subTotal
      return total + subTotal
    }, 0)
  }

  if (checkoutData.subscription) {
    const subTotal = convertItem({
      price: Number(checkoutData.subscription.amount),
      currency: checkoutData.subscription.currency,
      quantity: 1
    } as any, primaryCurrency, priceQuotes)

    total += subTotal
  }

  if (checkoutData.lineItems) {
    total += checkoutData.lineItems.reduce((total: number, p) => {
      const subTotal = convertItem(p, primaryCurrency, priceQuotes)
      if (p.chargingIntervalSeconds > 0) subscriptionTotal += subTotal
      return total + subTotal
    }, 0)
  }

  return [total, subscriptionTotal]
}
