import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { COUNTRIES, RADOM_COLORS } from '../util/Constants'
import styled, { keyframes } from 'styled-components'
import { FadeOut } from '../components/Animations'
import { ManagedPaymentMethod, NetworkToChainMap, getMethod } from '../util/Managed'
import { PrimaryButton, SecondaryButton } from '../components/Button'
import { TokenSelector } from '../components/PayWidget'
import { isEqual } from 'lodash'
import { InputLabel } from '../components/Input'
import RadomLogo from '../icons/Logo2'
import Dropdown from '../components/Dropdown'
import NumberInput from '../components/NumberInput'
import LoadingBar from '../components/LoadingBar'
import { pickTextColorBasedOnBgColor, shortAddress } from '../util/Util'
import Radom, { IDonationLink, IManagedPaymentMethod, IManagedPaymentMethodDetails, IManagedPaymentStatus } from '../api/Radom'
import { generateQRCode } from '../util/QRCode'
import CopyButton from '../components/CopyButton'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { createWalletClient, custom, createPublicClient, http, getContract, erc20Abi, parseUnits, Address, parseEther } from 'viem'
import { useDisconnect, useAccount } from 'wagmi'
import { Container } from '../components/PayInvoice'
import { useLocation } from 'react-router-dom'
import Checkbox from '../components/Checkbox'
import LinkExternal from '../icons/LinkExternal'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const AnimatedReceipt = styled.div`
  animation: ${fadeIn} 1s ease-in-out;
`

const BackgroundFadeIn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  animation: ${FadeOut} 1s ease;
  opacity: 0;
`

const BackgroundScaleIn = keyframes`
  from {
    background-size: 110%;
  }
  to {
    background-size: 100%;
  }
`

const Background = styled.div`
  background-size: cover;
  height: 275px;
  max-height: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-bottom: 60px;
  background-repeat: no-repeat;
  animation: ${BackgroundScaleIn} 0.5s ease;
  background-position: top center;
`

const FormRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

const FormInputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FormInputLabel = styled(InputLabel)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
`

const FormInput = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${RADOM_COLORS.GRAY_DARK};
  font-size: 14px;
  outline: 0;

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARK};
  }
  
  :focus {
    border-color: ${RADOM_COLORS.GRAY_DARK};
    box-shadow: 0 0 5px ${RADOM_COLORS.GRAY_DARK};
  }

  ::placeholder {
    color: ${RADOM_COLORS.GRAY_DARKER};
  }
`

const QRCodeWrapperOuter = styled.div`
  padding: 10px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`

const QRCodeWrapperInner = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const PaymentDetailsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0;
  max-width: 100%;
`

const Timer = styled.div`
  font-size: 14px;
  font-color: #3464E6;
  font-weight: 600;
  color: ${RADOM_COLORS.NEW_BLUE};
  margin-bottom: 10px;
  background: #E7EDFF;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 35px;
  opacity: 0px;
`

const IconContainer = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 4px;
  vertical-align: middle;
`

const PaymentDetailWithCopy = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const PaymentDetailText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
`

const PaymentDetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 15px;
`

const ClickableLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`

export const ExternalLinkText = styled.a`
  color: ${RADOM_COLORS.NEW_BLUE};
  align-items: center;
  display: flex;
  gap: 3px;
  text-decoration: none;

  &:hover {
    color: ${RADOM_COLORS.ORANGE};
    text-decoration: underline;
  }
`

function DonationLink(): ReactElement {
  const location = useLocation()
  const donationLinkId = location.pathname.replaceAll('/', '').replaceAll('donate', '')

  const [donationLink, setDonationLink] = useState<IDonationLink | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedMethod, setSelectedMethod] = useState<ManagedPaymentMethod | null>(null)
  const [donationAmount, setDonationAmount] = useState('')
  const [closeFn, setCloseFn] = useState(() => () => {})
  const [formData, setFormData] = useState<Record<string, string>>({})
  const [paymentDetails, setPaymentDetails] = useState<IManagedPaymentMethodDetails | null>(null)
  const [managedPaymentQrCode, setManagedPaymentQrCode] = useState<string>('')
  const [formStep, setFormStep] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState('')
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState<IManagedPaymentStatus | null>(null)

  const [tosAccepted, setTosAccepted] = useState(false)

  const { disconnect } = useDisconnect()
  const { address, isConnected, connector } = useAccount()
  const { open } = useWeb3Modal()
  const [isWalletLoading, setIsWalletLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const errorRef = useRef<HTMLDivElement>(null)

  const textColor = pickTextColorBasedOnBgColor(RADOM_COLORS.GRAY_LIGHTEST, 'rgba(255, 255, 255, 0.45)', 'rgba(0, 0, 0, 0.45)')

  useEffect(() => {
    if (donationLinkId !== 'previewdonation') {
      Radom.getDonationLink(donationLinkId)
        .then((data) => {
          setDonationLink(data)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setIsLoading(false)
        })
    } else {
      const previewData = window.location.hash.substring(1)
      if (previewData) {
        const parsedData = JSON.parse(Buffer.from(previewData, 'base64').toString('utf8'))
        setDonationLink(parsedData)
      }
      setIsLoading(false)
    }
  }, [donationLinkId, window.location.hash])

  useEffect(() => {
    if (donationLink && donationLink.methods?.length > 0) {
      const method = donationLink.methods[0]
      setSelectedMethod(getMethod(method.network, method.token))
    } else {
      setSelectedMethod(null)
    }
  }, [donationLink])

  useEffect(() => {
    if (paymentDetails?.expiresAt) {
      const interval = setInterval(() => {
        const now = new Date().getTime()
        const distance = new Date(paymentDetails.expiresAt).getTime() - now

        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        setTimeRemaining(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)

        if (distance < 0) {
          clearInterval(interval)
          setTimeRemaining('EXPIRED')
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [paymentDetails])

  useEffect(() => {
    if (paymentDetails) {
      const checkPaymentInterval = setInterval(() => {
        Radom.getManagedPaymentStatus(paymentDetails.paymentId)
          .then(res => {
            if (res.status === 'paymentConfirmed') {
              setPaymentStatus(res)
              setIsPaymentConfirmed(true)
              clearInterval(checkPaymentInterval)
            }
          }).catch(() => {})
      }, 1000)

      return () => clearInterval(checkPaymentInterval)
    }
  }, [paymentDetails])

  if (isLoading) {
    return <LoadingBar />
  }

  if (!donationLink) {
    return <div>Error loading donation link</div>
  }

  const methods = donationLink.methods

  function convertToIManagedPaymentMethod(method: ManagedPaymentMethod): IManagedPaymentMethod {
    return {
      network: method.tokenInfo ? method.tokenInfo.networkName : method.name,
      token: method.tokenInfo ? method.tokenInfo.tokenAddress : undefined
    }
  }

  const handleToSToggle = (e: React.MouseEvent): void => {
    e.stopPropagation()
    setTosAccepted((prev) => !prev)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, inputLabel: string): void => {
    setFormData({
      ...formData,
      [inputLabel]: e.target.value
    })
  }

  const handleNextStep = (): void => {
    if (donationLinkId === 'previewdonation') {
      return
    }

    setErrorMessage(undefined)

    if (!selectedMethod || !donationAmount) {
      setErrorMessage('Please select a payment method and enter a donation amount')
      return
    }

    const amount = parseFloat(donationAmount)
    if (isNaN(amount) || amount <= 0) {
      setErrorMessage('Please enter a valid donation amount')
      return
    }

    setFormStep(2)
  }

  const handleSubmitForm = async (): Promise<void> => {
    setErrorMessage(undefined)

    if (!selectedMethod) {
      return
    }

    if (donationLink.tosUrl && !tosAccepted) {
      setErrorMessage('You must accept the terms of service to proceed.')
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      return
    }

    const requiredFields = ['address1', 'city', 'postcode', 'country']
    const missingFields = requiredFields.filter((field) => !formData[field])

    if (missingFields.length > 0) {
      setErrorMessage('Please fill in all required address fields.')
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth' })
      }
      return
    }

    setIsSubmitting(true)

    const addressFields = {
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      postcode: formData.postcode,
      country: formData.country
    }

    const orderData = Object.keys(formData).map((key) => ({
      key,
      value: formData[key]
    }))

    const filteredOrderData = orderData.filter(
      (item) => !['address1', 'address2', 'city', 'postcode', 'country'].includes(item.key)
    )

    filteredOrderData.push({
      key: 'Address',
      value: `${addressFields.address1}, ${addressFields.address2 ? addressFields.address2 + ', ' : ''}${addressFields.city}, ${addressFields.postcode}, ${addressFields.country}`
    })

    const paymentMethod = convertToIManagedPaymentMethod(selectedMethod)

    try {
      const response = await Radom.createDonationLinkOrder({
        donationLinkId: donationLink.id,
        paymentMethod,
        orderData: filteredOrderData,
        amount: parseFloat(donationAmount),
        currency: donationLink.currency
      })

      if (response.paymentMethodDetails.managed) {
        setPaymentDetails(response.paymentMethodDetails.managed)
        generateManagedPaymentQrCode(response.paymentMethodDetails.managed)
        setFormStep(3)
      } else {
        console.error('Payment method details are not available')
      }
    } catch (err) {
      console.error('Error creating donation link order:', err)
      setErrorMessage(err.reason || err.message)
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const generateManagedPaymentQrCode = async (
    details: IManagedPaymentMethodDetails
  ): Promise<void> => {
    if (!details.paymentAddress) {
      console.error('Payment address is not available')
      return
    }

    const [qrCodeData] = await generateQRCode(details)
    setManagedPaymentQrCode(qrCodeData)
  }

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text)
  }

  const onClickOpenWallet = async (): Promise<void> => {
    if (!paymentDetails) {
      return
    }

    switch (paymentDetails.network) {
    case 'Bitcoin':
    case 'Solana':
    case 'Zcash':
    case 'ZcashTestnet':
    case 'Polkadot':
    case 'PolkadotTestnet':
      break
    case 'Ethereum':
    case 'SepoliaTestnet':
    case 'Polygon':
    case 'PolygonTestnet':
    case 'BNB':
    case 'BNBTestnet':
    case 'Base':
    case 'Arbitrum':
    case 'Avalanche':
      try {
        if (!address) {
          await open()
          return
        }

        if (!selectedMethod) {
          return
        }

        setIsWalletLoading(true)

        const chain = NetworkToChainMap[paymentDetails.network] as any
        const provider: any = await connector?.getProvider()

        const signer = createWalletClient({
          chain,
          transport: custom(provider)
        })

        if (connector?.switchChain) {
          const chainId = await connector.getChainId()
          if (chainId !== chain.id) {
            await connector?.switchChain({ chainId: chain.id })
          }
        }

        if (paymentDetails.token) {
          const publicClient = createPublicClient({
            chain,
            transport: http()
          })

          const contract = getContract({
            address: paymentDetails.token as any,
            abi: erc20Abi,
            client: { public: publicClient, wallet: signer }
          })

          const balance = await contract.read.balanceOf([address])
          const decimals = await contract.read.decimals()
          const parsedAmount = parseUnits(paymentDetails.amount.toString(), decimals)
          if (balance < parsedAmount) {
            setIsWalletLoading(false)
            return setErrorMessage(
              `Your wallet does not have enough ${getMethod(donationLink.methods[0].network, donationLink.methods[0].token).name} for payment.`
            )
          }

          await contract.write.transfer([
            paymentDetails.paymentAddress as Address,
            parseUnits(paymentDetails.amount, decimals)
          ], { account: address, chain })
        } else {
          const publicClient = createPublicClient({
            chain,
            transport: http()
          })

          try {
            const balance = await publicClient.getBalance({ address })
            if (balance < parseEther(paymentDetails.amount)) {
              setIsWalletLoading(false)
              return setErrorMessage('Your wallet does not have enough ether for payment.')
            }
          } catch (err) {
            console.error(err)
          }

          if (!signer) return

          await signer.sendTransaction({
            account: address,
            chain,
            to: paymentDetails.paymentAddress as Address,
            value: parseEther(paymentDetails.amount)
          })

          setIsWalletLoading(false)
        }
      } catch (err) {
        setIsWalletLoading(false)
        console.error(err)
      }
      break
    default:
      console.error('No wallet')
    }
  }

  if (isPaymentConfirmed && paymentStatus) {
    const transaction = paymentStatus.transactions[0]

    return (
      <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', zIndex: 5, position: 'relative' }}>
        <AnimatedReceipt style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '450px', margin: '20px auto', color: 'black', zIndex: 10 }}>
          <div style={{ marginBottom: '10px', backgroundImage: `url(${donationLink.imageUrl})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '150px', width: '150px', backgroundColor: 'white', borderRadius: '100%', border: '8px solid rgba(255, 255, 255, 1)', boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)', zIndex: 1 }} />
          <span style={{ fontSize: '16px', fontWeight: '700', marginBottom: '40px' }}>Thank you for supporting {donationLink.name ?? 'Unknown'}</span>

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '2.5px' }}>
            <span style={{ fontSize: '12px', fontWeight: '300', wordBreak: 'break-all', textAlign: 'left' }}><span style={{ fontWeight: 400, fontSize: '14px' }}>Donation Amount:</span> {paymentDetails?.amount} {paymentDetails?.token ?? paymentDetails?.network}</span>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '2.5px' }}>
            <span style={{ fontSize: '12px', fontWeight: '300', wordBreak: 'break-all', textAlign: 'left' }}><span style={{ fontWeight: 400, fontSize: '14px' }}>Payment Address:</span> {shortAddress(paymentDetails?.paymentAddress ?? '0x', 15)}</span>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
            <span style={{ fontSize: '12px', fontWeight: '300', wordBreak: 'break-all', textAlign: 'left' }}><span style={{ fontWeight: 400, fontSize: '14px' }}>Tx Hash:</span> {shortAddress(transaction.transactionHash ?? '0x', 20)}</span>
            <CopyButton onClick={() => copyToClipboard(transaction.transactionHash)}>Copy</CopyButton>
          </div>
        </AnimatedReceipt>
      </div>
    )
  }

  return (
    <div style={{ minHeight: '100vh', backgroundColor: 'white', fontSize: 14, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Background style={{ backgroundImage: `url(${donationLink.bannerImageUrl})`, backgroundColor: RADOM_COLORS.GRAY_MED }}>
          <div style={{
            backgroundImage: `url(${donationLink.imageUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            height: 150,
            width: 150,
            marginBottom: -60,
            backgroundColor: 'white',
            borderRadius: '100%',
            border: '8px solid rgba(255, 255, 255, 1)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
            zIndex: 1
          }} />
          <BackgroundFadeIn />
        </Background>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 20, gap: 40 }}>
          {formStep < 3 && (<div style={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', fontSize: 20 }}>
            <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>Donate with crypto to</span>
            <a href='#' style={{ fontWeight: 500, textDecoration: 'underline', color: RADOM_COLORS.BLACK }}>{donationLink.name ?? 'Unknown'}</a>
          </div>)}

          <div style={{ width: 'fit-content', maxWidth: '90%', display: 'flex', flexDirection: 'column', gap: 15 }}>

            {
              errorMessage && errorMessage.length > 0 &&
              <div ref={errorRef} style={{
                backgroundColor: RADOM_COLORS.ERROR,
                padding: 15,
                borderRadius: 5,
                marginBottom: 20,
                color: 'white',
                overflowWrap: 'break-word'
              }}>
                {errorMessage}
              </div>
            }

            {formStep === 1 && (
              <Container style={{ margin: 'initial', width: 450, padding: 0, maxWidth: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <InputLabel>Select donation method</InputLabel>
                  {methods?.length > 0
                    ? (
                      <Dropdown
                        noChevron={methods.length <= 1}
                        onCloseFn={fn => setCloseFn(() => fn)}
                        selectedContent={
                          <>
                            {selectedMethod && (
                              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                <div style={{ width: 25, height: 25, backgroundImage: `url(${selectedMethod.logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                                  {selectedMethod.tokenInfo && (
                                    <div style={{ background: 'rgba(255, 255, 255, 0.95)', borderRadius: '100%', width: 15, height: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: -1, right: -5, boxShadow: '0 0 1px' }}>
                                      <div style={{ width: 10, height: 10, backgroundImage: `url(${selectedMethod.tokenInfo.logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </div>
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div style={{ display: 'flex', alignItems: 'baseline', gap: 3 }}>
                                    <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>{selectedMethod.ticker}</span>
                                    <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                                      on {selectedMethod.tokenInfo?.networkName ?? selectedMethod.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        }
                        dropdownContent={
                          <>
                            {methods
                              .map(method => getMethod(method.network, method.token))
                              .filter(method => !isEqual(selectedMethod, method))
                              .map((method, i) => {
                                return (
                                  <TokenSelector
                                    hoverEffect
                                    key={i}
                                    isDisabled={false}
                                    isSelected={false}
                                    style={{ opacity: 1, border: 'none' }}
                                    onClick={() => {
                                      setSelectedMethod(method)
                                      closeFn()
                                    }}
                                  >
                                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                      <div style={{ width: 25, height: 25, backgroundImage: `url(${method.logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                                        {method.tokenInfo && (
                                          <div style={{ background: 'rgba(255, 255, 255, 0.95)', borderRadius: '100%', width: 15, height: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: -1, right: -5, boxShadow: '0 0 1px' }}>
                                            <div style={{ width: 10, height: 10, backgroundImage: `url(${method.tokenInfo.logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                          </div>
                                        )}
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', alignItems: 'baseline', gap: 3 }}>
                                          <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>{method.ticker}</span>
                                          <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                                            on {method.tokenInfo?.networkName ?? method.name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </TokenSelector>
                                )
                              })}
                          </>
                        }
                      />
                    )
                    : (
                      <LoadingBar style={{ width: '100%', height: 40 }} />
                    )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <InputLabel>Enter donation amount</InputLabel>
                  {donationLink
                    ? (
                      selectedMethod
                        ? (
                          <div style={{ position: 'relative' }}>
                            <div style={{ width: 25, height: 25, backgroundImage: `url(${selectedMethod.logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', position: 'absolute', left: 11, top: 7, zIndex: 1 }}>
                              {selectedMethod.tokenInfo && (
                                <div style={{ background: 'rgba(255, 255, 255, 0.95)', borderRadius: '100%', width: 15, height: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: -1, right: -5, boxShadow: '0 0 1px' }}>
                                  <div style={{ width: 10, height: 10, backgroundImage: `url(${selectedMethod.tokenInfo.logo})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                </div>
                              )}
                            </div>
                            <NumberInput
                              style={{ textIndent: 40, height: 40 }}
                              disabled={false}
                              autoFocus
                              placeholder="Enter donation amount"
                              min="0"
                              autoComplete="off"
                              value={donationAmount}
                              fontSize="14"
                              onUserInput={(e) => setDonationAmount(e)}
                            />
                            <div style={{ display: 'flex', gap: 5, position: 'absolute', right: 20, top: 12, opacity: 0.5, pointerEvents: 'none' }}>
                            </div>
                          </div>
                        )
                        : (
                          <div style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKER, borderRadius: 5, border: `1px solid ${RADOM_COLORS.GRAY_DARK}`, padding: '10px 15px' }}>
                        No available payment methods
                          </div>
                        )
                    )
                    : (
                      <LoadingBar />
                    )}
                  <div style={{ display: 'flex', gap: 5 }}>
                    {[25, 50, 100, 250, 500].map((a, i) =>
                      donationLink
                        ? (
                          <SecondaryButton
                            onClick={() => setDonationAmount(a.toString())}
                            key={i}
                            style={{
                              padding: 10,
                              borderRadius: 5,
                              border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                              flexGrow: 1,
                              display: 'flex',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              userSelect: 'none',
                              color: RADOM_COLORS.BLACK
                            }}>
                            {Intl.NumberFormat('en-US', { style: 'currency', currency: donationLink.currency, maximumFractionDigits: 0 }).format(a)}
                          </SecondaryButton>
                        )
                        : (
                          <LoadingBar key={i} style={{ width: '100%', height: 40 }} />
                        )
                    )}
                  </div>
                </div>
                <PrimaryButton
                  onClick={handleNextStep}
                  style={{ background: donationLink.primaryButtonColor, fontSize: 16, height: 50, marginTop: '20px' }}
                >
                  Next
                </PrimaryButton>
              </Container>
            )}

            {formStep === 2 && (
              <Container style={{ margin: 'initial', padding: 0, width: 450, maxWidth: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                  {
                    donationLink.inputFields?.length > 0 &&
                    Array(Math.ceil(donationLink.inputFields.length / 2)).fill(0).map((_, index) => {
                      const fieldsInPair = donationLink.inputFields.slice(index * 2, index * 2 + 2)
                      return (
                        <FormRow key={index}>
                          {fieldsInPair.map((field, subIndex) => (
                            <FormInputContainer key={subIndex}>
                              {field.dataType === 'Address'
                                ? (
                                  <>
                                    <FormInputLabel>Building no. and street name{field.isRequired && '*'}</FormInputLabel>
                                    <FormInput
                                      type="text"
                                      placeholder="Building no. and street name"
                                      required={field.isRequired}
                                      value={formData.address1 || ''}
                                      onChange={(e) => handleInputChange(e, 'address1')}
                                      style={{ marginBottom: '10px' }}
                                    />

                                    <FormInputLabel>Address 2</FormInputLabel>
                                    <FormInput
                                      type="text"
                                      placeholder="Address 2"
                                      value={formData.address2 || ''}
                                      onChange={(e) => handleInputChange(e, 'address2')}
                                      style={{ marginBottom: '10px' }}
                                    />

                                    <FormInputLabel>City/Town{field.isRequired && '*'}</FormInputLabel>
                                    <FormInput
                                      type="text"
                                      placeholder="City/Town"
                                      required={field.isRequired}
                                      value={formData.city || ''}
                                      onChange={(e) => handleInputChange(e, 'city')}
                                      style={{ marginBottom: '10px' }}
                                    />

                                    <FormInputLabel>Postcode{field.isRequired && '*'}</FormInputLabel>
                                    <FormInput
                                      type="text"
                                      placeholder="Postcode"
                                      required={field.isRequired}
                                      value={formData.postcode || ''}
                                      onChange={(e) => handleInputChange(e, 'postcode')}
                                      style={{ marginBottom: '10px' }}
                                    />

                                    <FormInputLabel>Country{field.isRequired && '*'}</FormInputLabel>
                                    <select
                                      required={field.isRequired}
                                      value={formData.country || ''}
                                      onChange={(e) => handleInputChange(e, 'country')}
                                      style={{ padding: '10px', borderRadius: '5px', border: `1px solid ${RADOM_COLORS.GRAY_DARK}`, fontSize: '14px', outline: 0 }}>
                                      <option value="" disabled>Select your country</option>
                                      {COUNTRIES.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                      ))}
                                    </select>
                                  </>
                                )
                                : (
                                  <>
                                    <FormInputLabel>{field.inputLabel}{field.isRequired && '*'}</FormInputLabel>
                                    <FormInput
                                      type={field.dataType === 'Email' ? 'email' : 'text'}
                                      placeholder={field.inputLabel}
                                      required={field.isRequired}
                                      value={formData[field.inputLabel] || ''}
                                      onChange={(e) => handleInputChange(e, field.inputLabel)}
                                    />
                                  </>
                                )}
                            </FormInputContainer>
                          ))}
                        </FormRow>
                      )
                    })
                  }
                </div>

                {
                  donationLink.tosUrl &&
                  <ClickableLabel onClick={handleToSToggle}>
                    <Checkbox checked={tosAccepted} onClick={handleToSToggle} />
                    <div style={{ display: 'inline' }}>
                      <span>I have read and agree to the </span>
                      <ExternalLinkText
                        href={donationLink.tosUrl}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        style={{ display: 'inline', whiteSpace: 'nowrap' }}
                        onClick={(e) => e.stopPropagation()}>
                        <span>terms of service</span>
                        <LinkExternal
                          style={{ marginLeft: 2, marginBottom: -2 }}
                          stroke={RADOM_COLORS.NEW_BLUE} width={13} />
                      </ExternalLinkText>.
                    </div>
                  </ClickableLabel>
                }

                <PrimaryButton
                  onClick={handleSubmitForm}
                  isLoading={isSubmitting}
                  style={{
                    background: donationLink.primaryButtonColor,
                    fontSize: 16,
                    height: 50,
                    marginTop: 20
                  }}>
                  Next
                </PrimaryButton>
              </Container>
            )}

            {formStep === 3 && (
              <PaymentDetailsContainer>
                <span style={{ color: '#747894', fontSize: '18px', fontWeight: '600' }}>
                  Donating <IconContainer style={{ backgroundImage: `url(${selectedMethod?.logo})` }} />
                  <span style={{ color: '#33324F' }}>{selectedMethod?.name}</span> on <IconContainer style={{ backgroundImage: `url(${selectedMethod?.tokenInfo?.logo ?? selectedMethod?.logo})` }} />
                  <span style={{ color: '#33324F' }}>{selectedMethod?.tokenInfo?.networkName ?? selectedMethod?.name}</span> network
                </span>
                <span style={{ color: '#555470', fontSize: '12px', fontWeight: '400', textAlign: 'center', paddingBottom: '10px' }}>
                  Connect to your wallet, scan the QR code or copy the {selectedMethod?.name} address to make a payment.
                </span>
                <QRCodeWrapperOuter>
                  <QRCodeWrapperInner>
                    <img src={managedPaymentQrCode} alt="QR Code" />
                  </QRCodeWrapperInner>
                </QRCodeWrapperOuter>
                <PaymentDetailColumn>
                  <Timer><span style={{ fontWeight: '500', color: '#618AF9', fontSize: '14px', marginRight: '2px' }}>Time Remaining</span> {timeRemaining}</Timer>
                </PaymentDetailColumn>
                <PaymentDetailWithCopy>
                  <PaymentDetailText>
                    <div style={{ color: '#33324F', fontSize: '12px', fontWeight: '500', marginBottom: '5px' }}>Payment Address</div>
                    <div style={{ color: '#747894', fontSize: '12px', fontWeight: '400' }}>{paymentDetails?.paymentAddress}</div>
                  </PaymentDetailText>
                  <CopyButton onClick={() => copyToClipboard(paymentDetails?.paymentAddress ?? '')}>Copy</CopyButton>
                </PaymentDetailWithCopy>
                <PaymentDetailWithCopy>
                  <PaymentDetailText>
                    <div style={{ color: '#33324F', fontSize: '12px', fontWeight: '500', marginBottom: '5px' }}>Total Amount</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconContainer style={{ backgroundImage: `url(${selectedMethod?.logo})` }} />
                      <span style={{ color: '#000000', fontSize: '16px', fontWeight: '500' }}>{paymentDetails?.amount} <span style={{ fontWeight: '400', color: '#555470' }}>{selectedMethod?.ticker}</span></span>
                    </div>
                  </PaymentDetailText>
                  <CopyButton onClick={() => copyToClipboard(paymentDetails?.amount ?? '')}>Copy</CopyButton>
                </PaymentDetailWithCopy>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  backgroundColor: address ? RADOM_COLORS.GRAY_LIGHTEST : 'initial',
                  borderBottomLeftRadius: 25,
                  borderBottomRightRadius: 25,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5
                }}>
                  {
                    address &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                      <div style={{ color: RADOM_COLORS.GRAY_DARKEST, display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <span style={{ textTransform: 'uppercase', fontSize: 10 }}>Connected wallet</span>
                        <span>{shortAddress(address, 10)}</span>
                      </div>
                      <SecondaryButton
                        disabled={isWalletLoading}
                        onClick={() => disconnect()}
                        style={{ padding: 0, border: 0, color: RADOM_COLORS.GRAY_DARKEST, textTransform: 'uppercase', fontSize: 10 }}>
                        Disconnect
                      </SecondaryButton>
                    </div>
                  }
                  <PrimaryButton
                    onClick={onClickOpenWallet}
                    isLoading={isWalletLoading}
                    spinnerColor={'white'}
                    style={{ background: donationLink.primaryButtonColor, color: 'white', width: '100%' }}>
                    {isConnected ? 'Open wallet' : 'Connect wallet'}
                  </PrimaryButton>
                </div>
              </PaymentDetailsContainer>
            )}
          </div>
          <a href='https://radom.network'
            target='_blank'
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 6,
              cursor: 'pointer',
              userSelect: 'none',
              fontSize: 14,
              color: 'rgba(0, 0, 0, 0.45)',
              fontWeight: 100,
              textDecoration: 'none',
              padding: 20,
              justifySelf: 'flex-end'
            }}
            rel="noreferrer">
            <span>Powered by</span>
            <RadomLogo width={60} height={14} fill={textColor} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default DonationLink
